.statsCard{
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.03) !important;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    :global{
        .ant-card-body{
            padding: 0;
            overflow: hidden;
        }
    }
    .cardBody{
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .imageDiv{
            overflow: hidden;
            // padding: 5px;
            height: 200px;
            @media (max-width:768px) {
                height: auto;
            }
            img{
                width: 100%;
                // height: 100%;
                object-fit: cover;
            }
        }
        .contentDiv{
            padding: 15px;
            text-align: left;
            width: 100%;
            min-height: 130px;

            .titleCard{
                max-width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                margin-bottom: 10px;
                font-size: 16px;
            }
            .descriptionTxt{
                height: 70px;
                margin-bottom: 0;
                font-size: 14px;
                color: rgb(107, 114, 128);
                @media (max-width: 768px) {
                    height: auto;
                }
            }
        }
    }
}