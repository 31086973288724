// userInfo.module.scss

.userInfo {
    width: 50% !important;

    :global {
        .ant-modal-header {
            background-color: var(--bg-theme-dark-color) !important;
            padding: 10px;
            border-radius: 5px;
        }
        .ant-modal-close {
            top: 25px !important;
            right: 35px;
            background-color: rgba(0, 0, 0, 0.06);
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        margin-top: 15px;

        label {
            font-weight: 700;
        }

        span {
            margin-top: 3px;
        }
    }
}
