.actionBtnsSection {
    padding-bottom: 2rem;

    .filterDiv {
        display: flex;
        flex-direction: row;
        gap: 20px;

        :global{
            .ant-input-affix-wrapper,.ant-select-selector{
                box-shadow: 0 0 3px 0 rgba(0,0,0,0.1);
            }
        }
        input {
            width: 300px;
            height: 35px;
        }

        .sortSelect {
            font-size: 14px;
            height: 45px;
            width: 45%;
        }
    }

    .actionBtnDiv {
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: flex-end;

        button {
            font-weight: 600;
            font-size: 15px;
            height: 45px;
            width: 150px;
        }
    }
}
.tableSection{
    padding: 2rem 0;
    .tableCard{
        padding: 10px;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
        .tableActionBtns{
            display: flex;
            gap: 10px;
            button{
                padding: 6px;
                height: auto;
                width: auto;
                svg{
                    font-size: 16px;
                }
            }
        }
    }
}

@media(max-width:768px){
    .filterDiv{
        width: 100%;
    }
    .actionBtnDiv{
        margin-top: 15px;
        width: 100%;
            button{
                width: 100% !important;
            }
        
    }

    .tableCard{
        overflow-x: auto;
        overflow-y: auto;
        max-height: 300px;
    }

   
}