.treeTitle {
  display: flex;
  align-items: center;
  padding: 10px 8px; /* Increased padding for more height */
  transition: background-color 0.3s;
}

.treeTitle:hover {
  background-color: #f0f0f0; /* Light gray background on hover */
  border-radius: 4px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.fileButton {
  padding: 0;
  margin-left: 8px;
  max-width: 100%;
  word-break: break-all;
  white-space: normal;
}

.lockedText {
  color: rgba(0, 0, 0, 0.45);
}

.unlockedText {
  color: rgba(0, 0, 0, 0.85);
}

.folderIcon {
  font-size: 16px; /* Adjust icon size as needed */
}

.fileIcon {
  font-size: 16px;
  margin-left: 5px;
}
.contentViewModal{
  :global{
    .ant-modal-close{
      background-color: rgba(255, 255, 255, 0.8);
      border: 1px solid;
      &:hover{
        background-color: rgba(255, 255, 255, 0.8);
        border: 1px solid;
      }
    }
  }
}