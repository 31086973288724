/* src/components/Header.module.scss */
.headerContainer {
    width: 100%;
    height: var(--header-width);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    border-bottom:1px solid #0000001f;

    .logoDiv{
      img{
        width: 250px;
        @media(max-width:768px) {
          width: auto;
          padding-left: 50px;
        }
      }
    }
    .headerProfile{
      width: var(--sidebar-width);
      border-right: 1px solid #0000001f;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 15px;
      @media (max-width: 768px) {
        order: 1;
      }
    }
    .headerContentDiv{
      width: calc(100% - var(--sidebar-width));
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 45px;
      @media (max-width: 768px) {
        padding: 0;
        width: auto;
      }
      .headerActions{
        display: flex;
        align-items: center;
        gap: 50px;
        .searchHeader{
          input{
            width: 200px;
          }
        }
        .headerActions {
          display: flex;
          align-items: center;

          .actionBtns {
            display: flex;
            gap: 10px; // Adjust spacing between buttons
          }

          .searchHeader {
            margin-left: 20px; // Adjust margin to space out search bar from buttons
          }
        }

        .actionBtns{
          display: flex;
          button{
            font-weight: 500;
            text-transform: uppercase;
            margin-top: 0;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            background-color: #F4F7FE !important;
            color: rgb(107, 114, 128) !important;
            padding: 9px;
            border-radius: 50%;
            border: 1px solid transparent;
            margin-left: 7.4px;
            margin-right: 7.4px;
            line-height: 1;
          }
          :global{
            .ant-badge {
              .ant-badge-count{
                  transform: translate(0%, -50%);
              }
            }
          }
        }
       
      }

    }
    .userDiv{
      // border: 1px solid #e5e5e5;
      border-radius: 8px;
      padding: 10px 20px;
      cursor: pointer;
      @media(max-width:768px) {
        padding: 10px;
      }
      .userDropdown{
        display: flex;
        align-items: center;
        gap:15px;
        :global{
          .ant-avatar{
            height: 45px;
            width: 45px;
            border-color: var(--primary-color);
            @media(max-width:768px){
                margin: 0 !important;
                margin-top: 0;  
                height: 35px;
                width: 35px; 
            }
          }
        }
        .dropdownIcon{
          font-size: 13px;
        }
        div{
          h6{
            margin-bottom: 3px;
          }
          p{
            margin-bottom: 0;
            font-size: 13px;
            color:var(--primary-color);
            font-weight: 600;
          }
        }
      }
    }
  }

