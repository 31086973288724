:global{
    .ant-drawer-body{
        padding-top: 55px;
        padding-bottom: 3.5rem !important;
        .ant-form-vertical{
            margin-top: 25px;
        }
    }
}
.formFooter {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 30px;
    border-top: 1px solid #e5e5e5;
    justify-content: flex-end;
    background: #ffffff;
    border-radius: 20px 0 0 20px;

    button {
        height: 45px;
        width: 150px;
    }
}
.formListItem{
    display: flex;
    align-items: baseline;
    gap: 10px;
    :global{
        .ant-form-item{
            width: 100%;
        }
    }
}
.highlightLabel{
    margin-bottom: 10px;
    span{
        color: #a1a1a1;
        font-style: italic;
    }
}