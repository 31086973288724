:global {
    .ant-drawer-body {
      padding-top: 55px; // Adjusts top padding inside the drawer
    }
  }
  
  .formFooter {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 30px;
    border-top: 1px solid #e5e5e5;
    justify-content: flex-end;
    background: #ffffff;
  
    button {
      height: 45px;
      width: 150px;
    }
  }
  
  .drawerMain {
    padding-bottom: 65px; // Ensures enough space at the bottom for the footer
  }
  
  .ant-form-item-label {
    margin-bottom: 8px; // Adjusts the space between label and input
  }
  
  .ant-form-item {
    margin-bottom: 16px; // Adds spacing between form items
  }
  
  .ant-input {
    height: 40px; // Standardizes input height
  }
  
  .ant-input-number {
    height: 40px; // Standardizes input number height
  }
  
  .ant-select {
    height: 40px; // Standardizes select input height
  }
  
  .ant-drawer-header {
    background: #ffffff; // Drawer header background color
  }
  