.actionBtnsSection {
    padding-top: 1rem;
    padding-bottom: 0;

    .filterDiv {
        display: flex;
        flex-direction: row;
        gap: 20px;

        :global{
            .ant-input-affix-wrapper,.ant-select-selector{
                box-shadow: 0 0 3px 0 rgba(0,0,0,0.1);
            }
        }
        input {
            width: 300px;
            height: 35px;
        }

        .sortSelect {
            font-size: 14px;
            height: 45px;
            width: 45%;
        }
    }

    .actionBtnDiv {
        display: flex;
        gap: 10px;
        flex-direction: row;
        justify-content: flex-end;

        button {
            font-weight: 600;
            font-size: 15px;
            height: 45px;
        }
    }
}
.tableSection{
    padding: 2rem 0;
    .tableCard{
        padding: 10px;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
        .tableActionBtns{
            display: flex;
            gap: 10px;
            button{
                padding: 6px;
                height: auto;
                width: auto;
                svg{
                    font-size: 16px;
                }
            }
        }
    }
}

.feeTabs{
    margin-top: 3rem;
}
.amountSymbolTag{
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {

    .actionBtnDiv {
        width: 100%;
button {
width: 100% !important;
}  
    }
.tableCard {
    overflow-x: auto !important; 
    max-width: 375px;
    margin-bottom: 1.5rem;

}
}