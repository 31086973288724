.contentSummary {
  margin-top: 1.5rem; // Spacing above the card
  padding: 1.5rem; // Padding inside the card
}

.cardHeading {
  font-size: 1.125rem; // Font size for section headings
  font-weight: 600; // Bold headings
  margin-bottom: 1rem; // Spacing below headings
}


.contentStats {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border: 1px dashed var(--primary-color);
  padding: 10px;

  span {
    font-size: 14px;
    font-weight: 500;

    strong {
      margin-right: 5px;
    }

    svg {
      font-size: 18px;
      color: var(--primary-color);
      margin-right: 3px;
    }
  }
}
