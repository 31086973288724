


.actionBtnsSection {
    padding-bottom: 1rem;


    .filterDiv {
        display: flex;
        flex-direction: row;
        gap: 20px;

        :global {

            .ant-input-affix-wrapper,
            .ant-select-selector {
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
            }
        }

        input {
            width: 300px;
            height: 35px;
        }

        .sortSelect {
            font-size: 14px;
            height: 45px;
            width: 60%;
        }
    }

    .actionBtnDiv {
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: flex-end;

        button {
            font-weight: 600;
            font-size: 15px;
            height: 45px;
            width: 150px;
        }
    }
}
.batchRow{
    margin-bottom: 2rem;
}

.batchDiv {
    height: 180px;
    display: flex;
    padding: 24px;
    position: relative;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    flex-direction: column;
    background-color: #ffffff;
    margin-top: 35px;
    overflow: hidden;




    .batchContent {
        display: flex;
        align-items: start;
        justify-content: space-between;

        .heading {
            // width: 80%;
            font-size: 18px;
            font-weight: 700;
            cursor: pointer;
            transition: 0.2s ease-in;
            display: inline-block;
            &:hover{
                color: var(--primary-color);
            }
        }
    }
    .addStudent{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        align-items: center;
        p{
            background-color: rgb(245, 237, 252);
            display: inline-block;
            padding: 3px 8px;
            border-radius: 8px;
            font-size: 13px;
            font-weight: 600;
            color: rgb(158, 73, 230);
            margin: 0;
            svg{
                font-size: 16px;
                margin-right: 2px;
            }
        }
        button{
            border-radius: 50px;
            border:1px dashed #d5d5d5;
            color:#898989;
            // border:1px dashed var(--primary-color);
            // color:var(--primary-color);
            font-weight: 500;
            font-size: 14px;

            &:hover{
                background-color: var(--bg-theme-dark-color) !important;
            }
        }
    }

    .footer {
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 7px;
        position: absolute;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: var(--primary-color);
        background-color: var(--bg-theme-dark-color);
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;

        .subjectName {
            color: var(--primary-color);
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
        }


        svg{
            font-size: 16px;
        }
    }

    .popup {
        gap: 1px;
        top: 25px;
        right: 0px;
        width: max-content;
        border: 1px solid #d8e0f0;
        display: flex;
        z-index: 8;
        overflow: hidden;
        position: absolute;
        min-width: 150px;
        box-shadow: 0px 2px 4px #28293d0a, 0px 8px 16px #60617029;
        border-radius: 14px;
        flex-direction: column;
        background-color: #ffffff;

        .listItem {
            color: rgba(125, 133, 146);
            cursor: pointer;
            display: flex;
            padding: 8px 8px 8px 12px;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
        }
    }
}
.detailViewCard{
    padding: 23px;
    background: white;
    border-radius: 19px;
    display: flex;
    gap: 25px 20px;
    flex-wrap: wrap;

    .labelValData{
        display: flex;
        width: calc(33.33% - 20px);
        gap: 10px;
        flex-direction: column;
       label{
        font-weight: 700;
       }
    }
}

.feeDetailViewCard{
    margin-top: 1.5rem;
    padding: 23px;
    background: white;
    border-radius: 19px;
    display: flex;
    gap: 25px 20px;
    flex-wrap: wrap;

    .labelValData{
        display: flex;
        width: calc(25% - 20px);
        gap: 10px;
        flex-direction: column;
       label{
        font-weight: 700;
       }
    }
}
.detailViewAction{
    padding: 23px;
    background: white;
    border-radius: 19px;
    :global{
        .ant-card-body{
            cursor: pointer;
        }
    }
}
.sectionHeading{
    margin-top:2rem ;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
}

:global{
    .ant-dropdown-menu-item{
        svg{
            font-size: 15px !important;
        }
    }
}

.liveClassRow {
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 1fr;
  gap: 10px;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #f0f0f0;
    .deleteBtn{
        padding: 0!important;
    }
  &:last-child {
    border-bottom: none;
  }

  // For each column item
  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  // Styling for the "Join" link
  a {
    color: #1890ff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  // Handling "Delete" button styling
  .ant-btn-link {
    color: #ff4d4f;
    padding: 0;
    margin-left: 10px;

    &:hover {
      color: #ff7875;
    }
  }
}

// Adjustments for smaller screens (Responsive Design)
@media (max-width: 768px) {
  .liveClassRow {
   
    gap: 15px;
  }

  div {
    justify-content: space-between;
  }
}
