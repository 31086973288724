.contentModal {

  :global {
    .ant-modal-content {
      min-height: 500px;
    }
      
    

    .ant-modal-title {
      font-size: 20px;
      font-weight: 700;
    }
  
   
  }

  .formFooter {
    margin-top: 20px;
    display: flex;
    gap: 8px;
    justify-content: flex-end;

    button {
      height: 40px;
    }
  }

  .addContentBar {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: #f5f5f5;
    // background-color: #f1fcff;
    padding: 18px;
    border-radius: 5px;

    button {
      // border-color: var(--primary-color);
      // color: var(--primary-color);
      color: #fff;

      svg {
        font-size: 16px;
      }
    }
  }

  .contentDiv {
    display: flex;
    gap: 10px;

    .newFolder {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 15%;
      cursor: pointer;
      transition: 0.2s ease-in-out;

      &.addedFolder {
        &:hover {
          color: var(--primary-color);

          svg {
            color: var(--primary-color);
          }
        }
      }

      .folderIconDiv {
        text-align: center;

        svg {
          font-size: 3rem;
          color: #565656;
          transition: 0.2s ease-in-out;
        }
      }

      .folderNameDiv {
        position: relative;
        text-align: center;

        h6 {
          margin-bottom: 0;
        }

        input {
          height: 30px;
        }

        .newFolderAction {
          position: absolute;
          bottom: -45px;
          left: 0;
          width: 100%;
          text-align: center;

          button {
            display: inline-block;
            width: 30px;
            height: 30px;
            border: 1px solid #e5e5e5;
            padding: 0;
            margin-right: 5px;

            svg {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
.addFolderInputDiv{
  input{
    margin-top: 10px;
    width: 40%;
  }
  button{
    margin-top: 10px;
    height: 40px;
  }
}
  .arrowIcon{
    font-size: 34px;
    cursor: pointer;

  }

  :global{
    .ant-list-item-action-split{
      display: none;
    }
    .ant-list .ant-list-item .ant-list-item-action>li{
      padding: 0px;
      display: inline-table;
    }
    .ant-list-item-action{
      display: flex;
      gap: 1.5px;
    }
  }
  .content{
    margin: 20px 0;
    display: flex;
    gap: 10px;
  }

  @media (max-width:768px){
    .content{
      flex-direction: column;
    }
  }