.searchInput {
  margin-bottom: 30px;
  width: 100%;
  max-width: 600px;
}

.announcementItem {
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 8px;
  padding: 16px;
}

.avatar {
  background-color: #f56a00;
}
.container{
  background-color: transparent;
  padding: 0;
}
.announcementList {
  .announcementListItem{
    background-color:#ffffff;
    border-radius:8px;
    padding: 8px;
    display:flex;
    margin-bottom: 10px;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
    p{
      margin: 15px 0 20px;
    }
  }
  .announcementByDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #f1f1f1;
    padding: 6px 12px;
    border-radius: 8px;
    p{
      margin: 0;
      font-size: 12px;
    }
  }
  .announcementByIcon{
  
    :global{
      .ant-list-item-meta-title{
        span{
          font-size: 12px !important;
        }
      }
      .ant-list-item-meta-description{
        font-size: 12px !important;
      }
    }
  }
  :global {
    .ant-list-item {
      display: block !important;
    }
    .ant-avatar {
      font-size: 12px;
    }
    .ant-list-item-meta-title {
      font-size: 12px !important;
    }
  }
}
