// freeResources.module.scss

.actionBtnsSection {
    padding-bottom: 1rem;

    .filterDiv {
        display: flex;
        flex-direction: row;
        gap: 20px;

        :global {
            .ant-input-affix-wrapper,
            .ant-select-selector {
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
            }
        }

        input {
            width: 300px;
            height: 35px;
        }

        .sortSelect {
            font-size: 14px;
            height: 45px;
            width: 45%;
        }
    }

    .actionBtnDiv {
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: flex-end;

        button {
            font-weight: 600;
            font-size: 15px;
            height: 45px;
        }
    }
}

.documentSection {
    margin-top: 2rem;
    .documentCard {
        background-color: #ffffff;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: hidden;
        padding: 10px 10px 5px;
        margin-bottom: 1.5rem;
    
        h5 {
            color: #000000;
            margin: 15px 3px;
            font-size: 16px;
            max-width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .documentThumbnailDiv {
            position: relative;
            height: 185px;
            overflow: hidden;

            > img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            .playIcon {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                flex-direction: column;
                width: calc(100% - 10px);
                height: calc(100% - 10px);
                margin: auto;
                top: 5px;
                left: 5px;
                cursor: pointer;

                svg {
                    font-size: 60px;
                    color: #ffffff;
                }
            }
        }
    }
}
.actions{
    display: flex;
    justify-content: space-between;
    margin: 15px 0 10px;
    gap: 20px;

    button{
        width: 32%;

        &:not(:last-child){
            border-color: var(--primary-color) !important;
            color: var(--primary-color);
        }
        svg{
            font-size: 18px;
        }
    }
}

@media (max-width:768px){
    .filterDiv{
        width: 100%;
    }
   
    .actionBtnDiv{
        width: 100%;
        margin-top: 15px;
            button{
                width: 100%;
            }
        
    }
}
