.paymentItem {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  a {
    margin-left: 10px;
  }
}
.couponHeading{
  font-size: 16px;
  margin-bottom: 10px;
}
.coupon {
  display: flex;
  width: 100%;
  gap: 8px;
  flex-wrap: wrap;
  span {
    border: 1px dashed var(--primary-color);
    font-size: 11px;
    text-align: center;
    border-radius: 50px;
    padding: 5px 8px; 
    font-weight: 600; 
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-transform: uppercase;
    
    &:hover{
      background-color: var(--primary-color);
      color: #ffffff;
    }
  }
}

