.testSeriesModal{
    width: 60% !important;
    :global{
        .ant-modal-body{
            padding: 20px;
        }
        .ant-modal-footer{
            display: flex;
            gap: 15px;
            justify-content: flex-end;
            button{
                height: 40px;
            }
        }
    }
    .seriesContent{
        .description{
            font-size: 14px;
            white-space: pre-line;
        }
    }
    .heading{
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    .smallContent{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 1.5rem;
        p{
            margin-bottom: 0;
            font-size: 14px;
            display: flex;
            align-items: center;
            gap: 5px;
            label{
                font-weight: 700;
            }
            svg{
                font-size: 17px;
            }
            &:last-child{
                svg{
                    font-size: 15px;
                }
            }
        }
    }
    .description{
        margin-top: 1.5rem;
    }
    .payHeading{
        margin-top: 20px;
        margin-bottom: 1rem;
        font-size: 18px;
        text-transform: capitalize;
    }
    .paymentTerms{
        display: flex;
        border: 1px dashed var(--primary-color);
        padding: 20px;
        justify-content: space-between;
        h5{
            width: 100%;
        }
        .paymentLabels{
            display: flex;
            flex-direction: column;
            gap: 10px;
            label{
                font-weight: 600;
            }
        }
    }
}
