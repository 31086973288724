.actionBtnsSection {
    padding-top: 2rem;
    .sectionHeading{
        margin-bottom: 2rem;
      }
  
    .sort {
      display: flex;
      flex-direction: row;
      gap: 20px;
  
      .sortSelect {
        font-size: 14px;
        height: 45px;
        width: 25%;
      }
      .datePicker{
        width: 25%;
        height: 45px;
        margin-left: 10px;
      }
      .applyFilterBtn{
        font-weight: 600;
        font-size: 18px;
        height: 45px;
        width: 170px;

      }
     
    }
    
    
  }
  

 

  .cardsContainer {
    padding: 10px;
  
    .customCard {
      border-radius: 22px;
      flex: 1;
     
      .cardIcon {
        font-size: 36px;
        color: #1890ff; // Primary Ant Design color
        margin-bottom: 10px;
      }
  
      .cardTitle {
        font-size: 20px;
        font-weight: bold;
        margin: 10px 0;

        .arrowUp{
          transform: rotate(45deg);
          font-size: 20px;
          margin-left: 5px;
          color: #0070C0; 
          vertical-align: middle;

        }
      }
  
      .cardSubtitle {
        font-size: 28px;
        color: #0070C0;
        margin: 8px 0;
      }
  
      .cardDescription {
        font-size: 14px;
        color: #777;
      }
      .arrowIcon {
        color: green;
        margin-right: 6px;
      }
    }
  }
  
  .singleAnalyticsCardSection {
    margin-top: 20px;
    display: flex;
   margin-bottom: 20px;
   
  
    .singleAnalyticsCard {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 20px;
      width: 100%;
      max-width: 1200px;
      text-align: left;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
      .cardHeader {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 15px;
        color: #333;
      }
    }
  }
  
