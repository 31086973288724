.deleteModal{
    :global{
        .ant-modal-content{
            border: 1px solid #ff4d4f;
        }
    }
    .deleteHeader{
        display: flex;
        align-items: center;
        gap: 5px;
        color: #ff4d4f;
        svg{
            font-size: 18px;
        }
    }
    .warningMsg{
        margin: 1rem 0;
    }
}