.testSeriesViewModal {
    width: 50% !important;

    :global {
        .ant-modal-header {
            background-color: var(--bg-theme-dark-color) !important; // Use the same theme
            padding: 10px;
            border-radius: 5px;
        }

        .ant-modal-close {
            top: 25px !important; // Align close button to match User Info modal
            right: 35px;
            background-color: rgba(0, 0, 0, 0.06); // Same background as User Info modal
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        margin-top: 15px;

        label {
            font-weight: 700; // Bold labels
        }

        span {
            margin-top: 3px; // Spacing below labels
        }
    }

    // Additional styles for highlights if necessary
    .highlights {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

        .ant-tag {
            margin: 4px; // Add space between tags
        }
    }
    .heighlightsList{
        padding-left: 15px;
        li{
            margin-top: 5px;
            margin-bottom: 10px;
            font-weight: 600;
        }
    }
}
