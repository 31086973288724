.pageHeader{
    padding-bottom: 1.5rem;
    display: flex;
    align-items: center;

    h3{
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 0;
    }
    .headerIcon{
        font-size: 14px;
        background-color: transparent;
        border: none;
        box-shadow: none;
        font-weight: 600;
        padding: 0;
        position: relative;
        padding-left:15px;
        margin-left: 15px;
        margin-bottom: 0;
        cursor: pointer;
        
        &::after{
            content: "";
            position: absolute;
            right: 100%;
            top: 0;
            bottom: 0;
            height: 18px;
            margin: auto;
            width: 2px;
            background-color: rgba(0,0,0,0.2);
        }
        &:hover{
            background-color: transparent !important;
            color: var(--primary-color);
        }
        svg{
            font-size: 18px;
        }
    }
}

@media (max-width: 768px){

    h3{
        margin-inline-end: auto;
    }
  
    .headerIcon{
        padding-right: 25% !important ;
        margin-left: 0px !important;
    } 
   
}