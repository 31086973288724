.courseData{
    padding: 20px;
    :global{
        .ant-card-bordered{
            margin-top: 12px;
        }
    }
}

.actionBtnDiv {
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: flex-end;

    button {
        font-weight: 600;
        font-size: 15px;
        height: 45px;
    }
}
