
.attendanceContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

  }

  .content {
    width: 80%;
    height: 70%;
    display: flex;
    flex-direction: column; // Stack cards vertically
    justify-content: flex-start;
    align-items: center;
  }

  .emptyCard, .attendanceCard {
    width: 100%;
    margin-bottom: 24px; // Spacing between cards
  }

  .calendarCard {
    width: 30%; // Fixed width for the calendar card
    margin-left: 24px; // Spacing from content
    display: flex;
    justify-content: center;
    align-items: center;
  }
  :global {
    .ant-layout {
      background: none !important; // Or your custom color
    }
  }

  .tableSection{
    padding: 0;
    .tableCard{
        padding: 10px;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
        margin-top: 12px;
        .tableActionBtns{
            display: flex;
            gap: 10px;
            button{
                padding: 6px;
                height: auto;
                width: auto;
                svg{
                    font-size: 16px;
                }
            }
        }
    }
}
