/* src/components/Layout.module.scss */
.layoutContainer {
    display: flex;
  }
  
  .mainContent {
    margin-left: var(--sidebar-width); 
    margin-top: var(--header-width); 
    padding: 30px 32px 31px;
    width: calc(100% - 250px);
    height: calc(100vh - 72px);
    overflow-y: auto;
    background-color: var(--bg-theme-color);
    position: relative;

    :global{
      .ant-layout{
        min-height: auto !important;
      }
    }
  }

  
  @media (max-width: 768px) {
    .layoutContainer {
      flex-direction: column; 
    
      
    }
  
    .mainContent {
      margin-left: 0; 
      margin-top: var(--header-width); 
      padding: 20px 16px; 
      width: 100%; 
      height: calc(100vh - 72px); 
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .mainContent {
      margin-left: var(--sidebar-width); 
      padding: 25px 24px; 
      width: calc(100% - var(--sidebar-width));
    }
  }