body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.spinner {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    position: fixed; /* Fix the position to the viewport */
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8); /* Optional: Semi-transparent background */
    z-index: 999; /* Ensure it appears above other content */
}
.copyright{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 9;
  background-color: var(--primary-color);
  padding: 5px;
  text-align: center;
}
.copyright p{
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}
.copyright p a{
  color: #fff;
  text-decoration: none;
}