.container {
    display: flex;
    justify-content: space-between;
    // background-color: transparent;
  }

  .leftSection {
    flex: 1;
    margin-right: 16px;
  }

  .rightSection {
    width: 32%;
    :global{
      .ant-card-head{
        background-color: #f1f1f1;
      }
      .ant-card-body{
        padding: 4px;
      }
    }
  }



  .batchInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    margin-top: 16px;
  }

  .batchInfoItem {
    display: flex;
    align-items: center;
    flex-basis: calc(50% - 16px); /* Two items per row */
    margin-bottom: 8px;
    svg{
      margin-right: 8px;
      font-size: 18px;
    }
  }

  .batchInfoItem span {
    margin-right: 8px;
  }

  .batchInfoItem strong {
    font-weight: bold;
  }

  .icon {
    margin-right: 8px;
    font-size: 18px;
  }

  .batchTimingsCard {
    margin-top: 16px;
  }

  .batchTimingsContent {
    text-align: center;
    .emptyDataDiv{
      color: #989898;
      svg{
        font-size: 25px;
        margin-bottom: 10px;
      }
      h4{
        font-size: 16px;
        font-weight: 400;
        font-style: italic;
      }
    }
  }


  :global {
    .ant-layout {
      background: none !important; // Or your custom color
    }
    .ant-card-bordered{
      margin-top: 12px;
      border-color: #e5e5e5;
    }
  }

@media (max-width: 768px) {
  .batchInfoItem{
    flex-basis: auto;
  }
}
    
  

