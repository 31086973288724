.actionBtnsSection {
    padding-bottom: 1rem;

    .filterDiv {
        display: flex;
        flex-direction: row;
        gap: 20px;

        :global {
            .ant-input-affix-wrapper,
            .ant-select-selector {
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
            }
        }

        input {
            width: 300px;
            height: 35px;
        }

        .sortSelect {
            font-size: 14px;
            height: 45px;
            width: 60%;
        }
    }

    .actionBtnDiv {
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: flex-end;

        button {
            font-weight: 600;
            font-size: 15px;
            height: 45px;
            width: 150px;
        }
    }
}

.productRow {
    margin-bottom: 2rem;
}

.productDiv {
    display: flex;
    padding: 12px;
    position: relative;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    flex-direction: column;
    background-color: #ffffff;
    margin-top: 35px;
    overflow: hidden;
    cursor: pointer;
    justify-content: center;
    gap: 10px;
    .priceCourse{
        display: inline-block;
        font-size: 13px;
        margin-bottom: 0;
        text-align: right;
        span{
            font-size: 15px;
            font-weight: 700;
        }
        div{
            color: #bcbcbc;
            text-decoration: line-through;
            display: inline-block;
            margin-left: 5px;
        }
    }
    .productCardImg {
        width: 100%;
        height: 200px;
        overflow: hidden;
        text-align: center;
        background-color: #f5f5f5;

        img {
            height: 100%;
            object-fit: cover;
        }
    }

    .productContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 15px;
        width: 100%;

        .smallContent {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            gap: 8px;

            p {
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 600;
                display: flex;
                gap: 5px;
                align-self: flex-start;

                svg {
                    font-size: 15px;
                }

                &:last-child {
                    svg {
                        font-size: 15px;
                    }
                }
            }
        }

        .heading {
            font-size: 14px;
            font-weight: 700;
            max-width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .addStudent {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        align-items: center;

        p {
            background-color: rgb(245, 237, 252);
            display: inline-block;
            padding: 3px 8px;
            border-radius: 8px;
            font-size: 13px;
            font-weight: 600;
            color: rgb(158, 73, 230);
            margin: 0;

            svg {
                font-size: 16px;
                margin-right: 2px;
            }
        }

        button {
            border-radius: 50px;
            border: 1px dashed #d5d5d5;
            color: #898989;
            font-weight: 500;
            font-size: 14px;

            &:hover {
                background-color: var(--bg-theme-dark-color) !important;
            }
        }
    }

    .footer {
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 7px;
        position: absolute;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: var(--primary-color);
        background-color: var(--bg-theme-dark-color);
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;

        .subjectName {
            color: var(--primary-color);
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
        }

        svg {
            font-size: 16px;
        }
    }

    .popup {
        gap: 1px;
        top: 25px;
        right: 0px;
        width: max-content;
        border: 1px solid #d8e0f0;
        display: flex;
        z-index: 8;
        overflow: hidden;
        position: absolute;
        min-width: 150px;
        box-shadow: 0px 2px 4px #28293d0a, 0px 8px 16px #60617029;
        border-radius: 14px;
        flex-direction: column;
        background-color: #ffffff;

        .listItem {
            color: rgba(125, 133, 146);
            cursor: pointer;
            display: flex;
            padding: 8px 8px 8px 12px;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;

            &:hover {
                background-color: rgba(240, 240, 240, 0.5); // Optional hover effect
            }
        }
    }
}

.segmentDiv {
    margin-bottom: 2rem;
    width: 60%;
    margin: auto;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.07);

    :global {
        .ant-segmented-item {
            width: 50%;
            height: 40px;
            align-items: center;
            display: flex;
            gap: 12px;
            justify-content: center;
            font-weight: 600;
            font-size: 15px;
            text-transform: capitalize;

            &.ant-segmented-item-selected {
                color: var(--primary-color);
            }

            .ant-segmented-item-icon {
                svg {
                    font-size: 18px;
                }
            }
        }
    }
}

.noDataDiv {
    p {
        color: #a5a5a5;
        margin-top: 2rem;
    }
}

@media (max-width:768px){
    .segmentDiv{
        width: 100%;
    }
    :global{
        .ant-modal-content{
            overflow-x: auto;
            max-height: 700px;
            margin: -69px;
        }
    }
}