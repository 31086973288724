.coursesSection {
    padding: 3rem 0 1.5rem;
    .courseCard{
        padding-bottom: 32px;
        margin-bottom: 2rem;
        
        .createdByTag{
            color: var(--primary-color);
            font-size: 13px;
            font-weight: 600;
            position: absolute;
            width: 100%;
            left: 0;
            padding: 5px 15px;
            bottom: 0;
            margin-bottom: 0;
            background-color: var(--bg-theme-dark-color);
            text-align: center;
            span{
                // background-color: var(--bg-theme-dark-color);
                display: inline-block;
                margin-left: 2px;
            }
        }
        .tagsDiv{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0 5px;
            min-height: 55px;
            
            .downloadCourse{
                background-color: rgb(245, 237, 252);
                display: inline-block;
                padding: 3px 5px;
                border-radius: 8px;
                font-size: 13px;
                font-weight: 600;
                color: rgb(158, 73, 230);
                margin: 0;
            }
            .courseMuliPrice{
              display: flex;
              width: 100%;
              .multiPriceDiv{
                p{
                  span{
                    font-weight: 700;
                  }
                }
              }
              h6{
                >div{
                    color: #bcbcbc;
                    text-decoration: line-through;
                    display: inline-block;
                    margin-left: 5px;
                }
                >span{
                  font-size: 16px;
                  font-weight: 700;
                }
              }
                >div{
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  font-size: 13px;
                }
            }
            .durationCourse{
              margin-bottom: 0;
              span{
                font-weight: 700;
              }
            }
            .priceCourse{
                display: flex;
                flex-direction: column;
                font-size: 14px;
                margin-left: 10px;
                margin-bottom: 0;
                h6{
                  font-size: 14px;
                  margin-bottom: 0;
                }
                span{
                    font-size: 16px;
                    font-weight: 700;
                }
                .discountedPrice{
                    color: #bcbcbc;
                    text-decoration: line-through;
                    display: inline-block;
                    margin-left: 5px;
                }
            }
        }
    }

}

.actionBtnsSection {
    padding-bottom: 3rem;

    .filterDiv {
        display: flex;
        flex-direction: row;
        gap: 20px;

        input {
            width: 300px;
            height: 35px;
        }

        .sortSelect {
            font-size: 14px;
            height: 45px;
            width: 200px;
        }
    }

    .actionBtnDiv {
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: flex-end;

        button {
            font-weight: 600;
            font-size: 15px;
            height: 45px;
            width: 150px;
        }
    }
}
:global{
    .ant-card-body{
        min-height: auto !important;
    }
    .imageCard_contentDiv__t-k0G{
        min-height: auto !important;
    }
}
.batchesSection {
    padding: 0 0 1.5rem;
.batchesHeading{
    margin-bottom: 0;
}
.batchRow {
    margin-bottom: 2rem;
  }
  
  .batchDiv {
    display: flex;
    padding: 11px 11px 45px;
    position: relative;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    flex-direction: column;
    background-color: #ffffff;
    margin-top: 35px;
  
    .batchImg {
      height: 175px;
      overflow: hidden;
      margin-bottom: 1rem;
      text-align: center;
      width: 100%;
      cursor: pointer;
      
      img {
        // max-height: 100%;
        object-fit: cover;
        // height: 100%;
        width: 100%;
      }
    }
  
    .batchContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .heading {
        // width: 80%;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        transition: 0.2s ease-in;
        display: inline-block;
  
        &:hover {
          color: var(--primary-color);
        }
      }
    }
    .addStudent {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 10px;
      align-items: center;
  
      p {
        background-color: rgb(245, 237, 252);
        display: inline-block;
        padding: 3px 8px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 600;
        color: rgb(158, 73, 230);
        margin: 0;
  
        svg {
          font-size: 16px;
          margin-right: 2px;
        }
      }
  



button {
    border-radius: 50px;
    border: 1px dashed #d5d5d5;
    color: #898989;
    // border:1px dashed var(--primary-color);
    // color:var(--primary-color);
    font-weight: 500;
    font-size: 14px;

    &:hover {
      background-color: var(--bg-theme-dark-color) !important;
    }
  }
}
  .footer {
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 7px;
    position: absolute;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: var(--primary-color);
    background-color: var(--bg-theme-dark-color);
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    .subjectName {
      color: var(--primary-color);
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
    }


    svg {
      font-size: 16px;
    }
  }
}
}
// .tableSection{
//     padding: 0 0 2rem;
//     .tableCard{
//         padding: 10px;
//         background-color: #ffffff;
//         border-radius: 8px;
//         box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);

//         .tableUserImg{
//             img{
//                 height: 30px;
//                 border-radius: 50%;
//                 margin-right: 5px;
//             }
//         }
//     }
// }
.sectionHeading{
  margin-bottom: 2rem;
}
@media (max-width: 768px) {
  .coursesSection{
    padding: 2rem 0 1.5rem;
  }
}