.statsCardSection{
    margin-bottom: 2.5rem;
    @media (max-width:768px) {
        margin-bottom: 1.5rem;
    }
}
.actionBtnsSection {
    padding-bottom: 1rem;

    .filterDiv {
        display: flex;
        flex-direction: row;
        gap: 20px;

        :global{
            .ant-input-affix-wrapper,.ant-select-selector{
                box-shadow: 0 0 3px 0 rgba(0,0,0,0.1);
            }
        }
        input {
            width: 300px;
            height: 35px;
        }

        .sortSelect {
            font-size: 14px;
            height: 45px;
            width: 45%;
        }
    }

    .actionBtnDiv {
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: flex-end;

        button {
            font-weight: 600;
            font-size: 15px;
            height: 45px;
            width: 150px;
        }
    }
}
.tableSection{
    padding: 2rem 0;
    .tableCard{
        padding: 10px;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
        .tableActionBtns{
            display: flex;
            gap: 10px;
            button{
                padding: 6px;
                height: auto;
                width: auto;
                &.additionInfoDropBtn{
                    svg{
                        transform: rotate(90deg);
                    }
                }
                svg{
                    font-size: 16px;
                }
            }
        }
    }
}

@media (max-width: 768px){
    .filterDiv{
        width: 100%;

    }

    .actionBtnDiv {
        width: 100%;
        margin-top: 15px;
        button{
            width: 100% !important;
        }
    }
    .tableCard{
        overflow-x: auto !important;
        margin-bottom: 12px;
     
        
      
    }
  

}