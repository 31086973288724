.tableSection{
    padding: 0;
    .tableCard{
        padding: 10px;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
        .tableActionBtns{
            display: flex;
            gap: 10px;
            button{
                padding: 6px;
                height: auto;
                width: auto;
                svg{
                    font-size: 16px;
                }
            }
        }
    }
}
.actionBtnsSection {
    padding-bottom: 1rem;

    .filterDiv {
        display: flex;
        flex-direction: row;
        gap: 20px;

        :global{
            .ant-input-affix-wrapper,.ant-select-selector{
                box-shadow: 0 0 3px 0 rgba(0,0,0,0.1);
            }
        }
        input {
            width: 300px;
            height: 35px;
        }

        .sortSelect {
            font-size: 14px;
            height: 45px;
            width: 45%;
        }
    }

    .actionBtnDiv {
        display: flex;
        gap: 20px;
        flex-direction: row;

        button {
            font-weight: 600;
            font-size: 13px;
            height: 36px;
            width: auto;
        }
    }
}
.formFooter {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 30px;
    border-top: 1px solid #e5e5e5;
    justify-content: flex-end;
    background: #ffffff;
    border-radius: 20px 0 0 20px;

    button {
        height: 45px;
        width: 150px;
    }
}

@media(max-width:768px)
{
   .actionBtnDiv{
    button{
        width: 100% !important;
    }
}
    .tableCard{
        margin-bottom: 3.5rem;
        overflow-x: auto;
        overflow-y: auto;
        max-width: 400px;
        max-height: 300px;
    }
}