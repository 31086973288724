
.actionBtnsSection {
  padding-bottom: 1rem;


  .filterDiv {
    display: flex;
    flex-direction: row;
    gap: 20px;

    :global {

      .ant-input-affix-wrapper,
      .ant-select-selector {
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
      }
    }

    input {
      width: 300px;
      height: 35px;
    }

    .sortSelect {
      font-size: 14px;
      height: 45px;
      width: 60%;
    }
  }

  .actionBtnDiv {
    display: flex;
    gap: 20px;
    flex-direction: row;
    justify-content: flex-end;

    button {
      font-weight: 600;
      font-size: 15px;
      height: 45px;
      width: 150px;
    }
  }
}

.batchRow {
  margin-bottom: 2rem;
  .addedDetails{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;
    span{
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      svg{
        font-size: 18px;
      }
      strong{
        margin-right: 5px;
        svg{
          font-size: 18px;
        }
      }
    }
  }
}

.badge {
  position: absolute;
  top: 19px;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 11px;
  color: #fff;
  font-weight: 500;
  right: 19px;
}

.published {
  color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f; // Green for published
}

.unpublished {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96; // Red for unpublished
}

.batchDiv {
  display: flex;
  padding: 11px 11px 45px;
  position: relative;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  flex-direction: column;
  background-color: #ffffff;
  margin-top: 35px;

  .batchImg {
    height: 185px;
    overflow: hidden;
    margin-bottom: 1rem;
    text-align: center;
    cursor: pointer; 

    img {
      // max-height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .batchContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
  

    .heading {
      // width: 80%;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
      transition: 0.2s ease-in;
      display: inline-block;

      &:hover {
        color: var(--primary-color);
      }
    }
    
  }

  .addStudent {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 10px;
    align-items: center;

    p {
      background-color: rgb(245, 237, 252);
      display: inline-block;
      padding: 3px 8px;
      border-radius: 8px;
      font-size: 13px;
      font-weight: 600;
      color: rgb(158, 73, 230);
      margin: 0;

      svg {
        font-size: 16px;
        margin-right: 2px;
      }
    }

    button {
      border-radius: 50px;
      border: 1px dashed #d5d5d5;
      color: #898989;
      // border:1px dashed var(--primary-color);
      // color:var(--primary-color);
      font-weight: 500;
      font-size: 14px;

      &:hover {
        background-color: var(--bg-theme-dark-color) !important;
      }
    }
  }

  .footer {
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 7px;
    position: absolute;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: var(--primary-color);
    background-color: var(--bg-theme-dark-color);
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    .subjectName {
      color: var(--primary-color);
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
    }


    svg {
      font-size: 16px;
    }
  }

  .popup {
    gap: 1px;
    top: 25px;
    right: 0px;
    width: max-content;
    border: 1px solid #d8e0f0;
    display: flex;
    z-index: 8;
    overflow: hidden;
    position: absolute;
    min-width: 150px;
    box-shadow: 0px 2px 4px #28293d0a, 0px 8px 16px #60617029;
    border-radius: 14px;
    flex-direction: column;
    background-color: #ffffff;

    .listItem {
      color: rgba(125, 133, 146);
      cursor: pointer;
      display: flex;
      padding: 8px 8px 8px 12px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}

.detailViewCard {
  padding: 23px;
  background: white;
  border-radius: 19px;
  display: flex;
  gap: 25px 20px;
  flex-wrap: wrap;

  .labelValData {
    display: flex;
    width: calc(33.33% - 20px);
    gap: 10px;
    flex-direction: column;

    label {
      font-weight: 700;
    }

    .linkLabel {
      color: #007bff; // or another link color of your choice
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.batchFeeStructure {
  .feeDetailViewCard {
    margin-bottom: 2.5rem;
  }
}

.feeDetailViewCard {
  margin-top: 1.5rem;
  padding: 23px;
  background: white;
  border-radius: 19px;
  display: flex;
  gap: 25px 20px;
  flex-wrap: wrap;

  .labelValData {
    display: flex;
    width: calc(25% - 20px);
    gap: 10px;
    flex-direction: column;

    label {
      font-weight: 700;
    }
  }
}

.detailViewAction {
  padding: 23px;
  background: white;
  border-radius: 19px;

  :global {
    .ant-card-body {
      cursor: pointer;
    }
  }
}

.sectionHeading {
  margin-top: 2rem;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}

:global {
  .ant-dropdown-menu-item {
    svg {
      font-size: 15px !important;
    }
  }
}

.batchClassesTable {
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .actionBtnDiv{
    margin-top: 15px;
    width: 100% !important;
  button {
   width: 100% !important;
  }
}
.batchDiv{
  margin-bottom: 20px
}
.detailViewAction{
  margin-bottom: 4.5rem !important;
}
.actionBtnsSection,
.filterDiv{
  flex-direction: column !important;

.sortSelect {
  width: 100% !important;
}
}

:global{
   .ant-table-container{
    overflow-x: auto;
  }
}
}
