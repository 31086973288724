.unreadNotification{
    background-color:#e5f2ff;
    // border-radius: 8px;
}
:global{
    .ant-list-item{
        padding: 15px !important;
        // margin-top: 1rem !important;
    }
}
.notificationDrawer{
    padding: 0;
    :global{
        .ant-drawer-body{
            padding: 0;
            
        }
    }
    .announceMsg{
        white-space: break-spaces;
    }
}