.login-container {
  display: flex;
  justify-content: center;
  background-color: #f0f2f5;

  .login-page {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .login-left {
      background: linear-gradient(to left,#eaeaea,var(--bg-theme-dark-color),var(--bg-theme-dark-color),#eaeaea);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: sticky;
      top: 0;
      height: 100vh;
      padding: 50px;
      
      .illustration {
        width: 90%;
        max-width: 300px;
        margin-bottom: 40px;
      }

      .welcome-title {
        font-size: 24px;
        margin-bottom: 20px;
      }

      .login-text {
        font-size: 16px;
        color: #777;
      }
    }

    .login-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // padding: 50px;
      background-color: #fff;
      overflow-y: auto;
      height: 100vh;
      position: relative;
      // background: linear-gradient(to bottom, transparent, skyblue);
      .loginForm{
        padding: 40px 100px;
        .otp-title{
          font-size: 18px;
        }
          :global{
            .ant-form-item-control-input-content{
              box-shadow: none;
            }
          }
      }
      .img-logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        text-align: center;
        // margin-inline-start: -95px;
        a{
          color: #898989;
          text-transform: capitalize;
          text-decoration: none;
          font-weight: 500;
          &:hover{
            color: var(--primary-color);
          }
        }
        .baluja {
          height: 60px;
          width: auto;
         
        }
      }
      .signin-title {
        text-align: left;
        margin: 2rem 0 2rem;
        font-weight: 600;
        font-size: 30px;
        color: #000;
      }

      .login-form {
        width: 100%;
      }

      .form-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 18px;

        label {
          font-weight: 500;
          color: #685f78;
          display: inline-block;
          margin-bottom: 15px;
        }

        input {
          padding: 12px 16px;
          font-size: 16px;
          border: 1px solid #ccc;
          border-radius: 6px;
          outline: none;
          transition: border-color 0.3s ease;
          height: 50px;
          box-shadow: none;

          &:focus {
            border-color: #1890ff;
          }
        }

        .input-error {
          color: red;
          font-size: 12px;
          margin-top: 5px;
        }
      }

      .submit-button {
        margin-top: 30px;

        button {
          background-color: #02b3e4;
          color: #fff;
          border: none;
          font-size: 16px;
          border-radius: 6px;
          cursor: pointer;
          width: 100%;
          transition: background-color 0.3s ease;
          border-radius: 5px;
          padding: 12px 15px;
          font-weight: 700;
          height: 50px;

          &:hover {
            background-color: #40a9ff;
          }
        }
      }

      .social-signin-container {
        padding: 40px;
        text-align: center;
        // position: absolute; 
        background-color: var(--bg-theme-dark-color);

        .signin-text {
          margin-bottom: 10px;
          font-weight: bold;
        }

        .signin-link {
          display: inline-flex;
          align-items: center;
          color: black;
          font-weight: 500;
          text-decoration: none;
          font-size: 16px;
          margin: 15px 8px;

          &:hover {
            text-decoration: none;
            color: var(--primary-color);
          }

          svg {
            margin-right: 6px;
            font-size: 18px;
          }
        }

        .separator {
          color: #777;
          font-size: 16px;
          margin: 0 8px;
        }

        .new-user {
         
          font-size: 22px;
          font-weight: 500;

          a {
            text-decoration: none;
            font-weight: 700;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .login-page {
      flex-direction: column;
    }

    .login-left,
    .login-right {
      width: 100%;
      padding: 40px;
      position: static;
      height: auto;
    }

    .social-signin-container {
      padding: 20px;
      margin: 20px 0;
      width: 100%;
      max-width: none;
      position: static; /* Reset to normal in mobile view */
    }

    .form-item {
      input {
        padding: 10px 14px;
      }
    }

    .submit-button {
      margin-top: 20px;
      button {
        width: 100%;
      }
    }

    
  }
}

@media (max-width: 576px) {
  .login-container {
    .login-page {
      .login-left,
      .login-right {
        padding: 30px;
      }

      .login-left {
        padding: 30px 20px;
      }

      .login-right {
        .loginForm{
          padding: 20px;
        }
        .signin-title {
          font-size: 24px;
        }

        .form-item {
          label {
            font-size: 14px;
            margin-bottom: 10px;
          }

          input {
            font-size: 14px;
            padding: 10px 14px;
            height: 45px;
          }

          .input-error {
            font-size: 10px;
          }
        }

        .submit-button {
          margin-top: 20px;

          button {
            font-size: 14px;
            padding: 10px 14px;
            height: 45px;
          }
        }

        .social-signin-container {
          padding: 20px;
          .signin-text {
            font-size: 14px;
          }

          .signin-link {
            font-size: 14px;
            margin: 10px 8px;
          }

          .separator {
            font-size: 14px;
          }

          .new-user {
            font-size: 12px;
          }
        }
      }
    }
  }
}