:global {
    .ant-steps {
      position: absolute;
      top: 57px;
      z-index: 99;
      background: #fff;
      width: 100%;
      left: 0;
      padding: 10px 25px;
    }
    .ant-drawer-body {
      padding-top: 55px;
    }
  }
  
  .drawerMain {
    padding-bottom: 65px;
    margin: 20px 0;
  
    .batchInfoForm {
      margin: 20px 0;
  
      .note {
        display: flex;
        gap: 5px;
        margin: 5px 0;
        color: #898989;
        font-size: 12px;
      }
  
      .categoryList {
        display: flex;
        flex-direction: column;
  
        .categoryRow {
          display: flex;
          gap: 10px;
        }
      }
  
      :global {
        .ant-space-item {
          width: 45%;
  
          &:last-child {
            width: 10%;
          }
        }
      }
    }
  
    .formFooter {
      display: flex;
      gap: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 10px 30px;
      border-top: 1px solid #e5e5e5;
      justify-content: flex-end;
      background: #ffffff;
      border-radius: 20px 0 0 20px;
  
      button {
        height: 45px;
        width: 150px;
      }
    }
  }
  