
.searchInput {
  margin-bottom: 16px;
}

.addButton {
  margin-top: 16px;


}
.formFooter {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 30px;
    border-top: 1px solid #e5e5e5;
    justify-content: flex-end;
    background: #ffffff;

    button {
        height: 45px;
        width: 150px;
    }
}

.registeredUserContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.searchInput {
  margin-bottom: 16px;
}

.footerContainer {
  display: flex;
  // justify-content: space-between;
  padding-top: 16px;
  padding-right: 16px;
  margin-top: auto; /* Pushes the footer to the bottom */
}


.regUserList{
  .addedUsersDetails{
    display: flex;
    gap: 20px;
  }
}
.enrolledStudentDrawer{
  :global{
    .ant-list-item{
      padding: 15px 0 !important;
    }
  }
}
