.profileTabs{
    padding: 25px 15px;
    background-color: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
    border-radius: 8px;
    width: 80%;
    svg{
        font-size: 16px;
    }
    :global{
        .ant-btn-primary{
            height: 40px;
        }
        .ant-tabs-nav-list{
            font-weight: 600;
        }
    }
}
.detailsDiv{
    display: flex;
    width: 100%;
    align-items: flex-start;
    .profileImgdiv{
        width: 100px;
        border: 1px dashed #d5d5d5;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        :global{
            .ant-avatar{
                height: 60px;
                width: 60px;
                font-size: 24px;
            }
        }
    }
.detailViewCard{
    padding: 23px;
    background: white;
    border-radius: 19px;
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    width: calc(100% - 100px);

    .labelValData{
        display: flex;
        width: 100%;
        gap: 10px;
        flex-direction: column;
       label{
        font-weight: 700;
        min-width: 80px;
       }
    }
}
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; // Adjust space between header and form
}
.form {
    margin-left: 20px;

    .input {
        width: 250px;;
    }

    .formFooter {
    display: flex;
    gap: 20px;
    // position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 30px;
    border-top: 1px solid #e5e5e5;
    justify-content: flex-end;
    background: #ffffff;

    button {
        height: 45px;
        width: 150px;
    }
}

}
// :global{
//     .ant-tabs-tabpane{
//         padding-left: 0 !important;
//     }
// }


