.container {
  display: flex;
}

.leftSection {
  flex: 3;
  margin-right: 20px;
}



.card {
  border-radius: 10px;
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  .ant-list-item-meta {
    flex-grow: 1;
  }

  .avatarIcon {
    background-color: #1890ff;
    padding: 15px;
  }

  .duration {
    color: #888;
    margin-left: 2px;
  }
}

:global {
  .ant-layout {
    background: none !important; // Or your custom color
  }
}
.descDate{
  display: flex;
  align-items: center;
  svg{
    font-size: 16px;
    margin-right: 4px;
  }
}


.listItem {
padding: 16px;
border-bottom: 1px solid #f0f0f0;
display: flex;
justify-content: space-between;
align-items: center;
transition: background 0.3s;
}

.listItem:hover {
background: #f9f9f9;
}

.avatarIcon {
background-color: #f56a00;
font-size: 18px;
}

.title {
font-size: 16px;
font-weight: 600;
margin: 0;
}

.descDate {
color: #888;
font-size: 14px;
}

.actions {
display: flex;
gap: 12px;
align-items: center;
}

.duration {
font-size: 14px;
color: #555;
}

.joinButton {
background-color: #1890ff;
border: none;
}

.viewButton {
background-color: #f0f0f0;
color: #333;
border: 1px solid #d9d9d9;
}

