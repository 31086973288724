.uploadImagePreview{
    height: 80px;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 1rem;
    img{
        max-height: 100%;
    }
}
.imageInstruction{
    margin: 10px 0 10px;
    color: #989898;
    font-style: italic;
}
.uploadComponent{
    .imgWithInstruction{
        display: flex;
        flex-direction: column;
    }
    :global{
        .ant-upload{
            display: flex;
            gap: 20px;
            align-items: center;
        }
    }
}