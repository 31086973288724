.statsCardSection{
    margin-bottom: 2.5rem;
}
.actionBtnsSection {
    padding-bottom: 1rem;

    .filterDiv {
        display: flex;
        flex-direction: row;
        gap: 20px;

        :global{
            .ant-input-affix-wrapper,.ant-select-selector{
                box-shadow: 0 0 3px 0 rgba(0,0,0,0.1);
            }
        }
        input {
            width: 300px;
            height: 35px;
        }

        .sortSelect {
            font-size: 14px;
            height: 45px;
            width: 45%;
        }
    }

    .actionBtnDiv {
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: flex-end;

        button {
            font-weight: 600;
            font-size: 15px;
            height: 45px;
        }
    }
}
.tableSection{
    padding: 2rem 0;
    .tableCard{
        padding: 10px;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
        .tableActionBtns{
            display: flex;
            gap: 10px;
            button{
                padding: 6px;
                height: auto;
                width: auto;
                &.additionInfoDropBtn{
                    svg{
                        transform: rotate(90deg);
                    }
                }
                svg{
                    font-size: 16px;
                }
            }
        }
    }
}
.segmentDiv{
    margin-bottom: 2rem;
    :global{
        .ant-segmented{
            width: 60%;
            margin: auto;
            box-shadow: 0 0 5px 0 rgba(0,0,0,0.07);
            .ant-segmented-item{
                width: 50%;
                height: 40px;
                align-items: center;
                display: flex;
                gap: 12px;
                justify-content: center;
                font-weight: 600;
                &.ant-segmented-item-selected{
                    color: var(--primary-color);
                }
                .ant-segmented-item-icon{
                    svg{
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
.videoSection{
    margin-top: 2rem;
    .videoCard{
        background-color: #ffffff;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
        border-radius: 3px;
        overflow: hidden;
        padding: 5px;
        margin-bottom: 1.5rem;


        h5{
            color: #000000;
            margin: 12px 5px 7px;
            font-size: 16px;
        }
        .videoThumbnailDiv{
            position: relative;

            >img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            .playIcon{
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                flex-direction: column;
                width: calc(100% - 10px);
                height: calc(100% - 10px);
                margin: auto;
                top: 5px;
                left: 5px;
                cursor: pointer;
                // background-color: rgba(0,0,0,0.5);
                border-radius: 3px;
                overflow: hidden;

                svg{
                   font-size: 60px;
                   color: #ffffff;
                }
            }
        }
    }
}
.formFooter {
    display: flex;
    gap: 0;
    position: absolute;
    bottom: 31px;
    left: 0;
    width: 100%;
    padding: 10px 30px;
    border-top: 1px solid #e5e5e5;
    justify-content: flex-end;
    background: #ffffff;
    border-radius: 20px 0 0 20px;

    button {
        height: 45px;
        width: 150px;
    }
}
