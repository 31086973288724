.actionBtnsSection {
    padding-top: 1rem;
   

    .actionBtnDiv {
        display: flex;
        gap: 10px;
        flex-direction: row;
        justify-content: flex-end;

        button {
            font-weight: 600;
            font-size: 15px;
            height: 45px;
        }
    }
}
.tableSection{
    padding: 2rem 0;
    .tableCard{
        padding: 10px;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
        .tableActionBtns{
            display: flex;
            gap: 10px;
            button{
                padding: 6px;
                height: auto;
                width: auto;
                svg{
                    font-size: 16px;
                }
            }
        }
    }
}



@media (max-width: 768px) {

    .actionBtnDiv {
        width: 100%;
button {
width: 100% !important;
}  
    }
.tableCard {
    overflow-x: auto !important; 
    max-width: 375px;

}
}