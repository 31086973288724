.installmentDetails {
    display: flex;
    flex-direction: column; /* Stack details vertically */
    gap: 4px; /* Space between details */
    color: #555; /* Dark gray for text color */
    font-size: 14px; /* Set a font size for readability */
}

.installmentDetails .ant-typography {
    display: inline-block; /* Aligns typography inline */
}

.installmentDetails strong {
    color: #007bff; /* Blue color for emphasis on status */
}

.installmentDetails span {
    font-weight: normal; /* Normal weight for non-emphasized text */
}

.ant-list-item {
    border-bottom: 1px solid #d9d9d9; /* Separator line between items */
}

.ant-list-item:last-child {
    border-bottom: none; /* Remove bottom border for the last item */
}

.ant-list-item-meta-title {
    font-size: 16px; /* Increase font size for installment title */
}

.ant-list-item-meta-description {
    font-size: 14px; /* Set a consistent size for description */
}

@media(max-width:768px){
    :global{
        .ant-tabs, .ant-tabs-content-holder{
                  overflow-x: auto;
        }
    }
}