.sidebarContainer {
    width: var(--sidebar-width);
    // background-color: #fbd7d7;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 60px;
    left: 0;
    height: 100vh;
    overflow-y: auto;
    padding: 20px 0 110px;
    border-right: 1px solid #0000001f;

    ul {
        padding: 0;
        list-style-type: none;
        box-sizing: border-box;

        li {
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
            transition: all 0.3s ease;
            overflow: hidden;
            padding: 2px 5px;

            a {
                display: flex;
                align-items: center;
                text-decoration: none;
                padding: 12px;
                cursor: pointer;
                transition: 0.3s ease all;
                width: 100%;
                border-radius: 5px;
                box-sizing: border-box;
                color: rgba(0, 0, 0, 0.88);
                font-size: 14px;
                font-weight: 600;

                &:hover {
                    color: var(--primary-color);
                }

                svg {
                    font-size: 20px;
                    margin-right: 10px; // Space between icon and label
                }
            }

            .active {
                font-weight: 700;
                color: var(--primary-color);
                background-color: var(--bg-theme-dark-color) !important;
            }
        }
    }

   
    .subMenuIconLeft {
        a {
            svg {
                margin-right: 66px; 
                margin-left: -10px; 
            }
            padding-left: 20px; 
        }
    }
}
:global{
    .ant-menu-submenu{
        .ant-menu-submenu-title{
            padding-left: 20px !important;
            svg{
                font-size: 20px !important;
            }
        }
        .ant-menu-sub{
            li{
                padding-left: 32px !important;
            }
        }
    }
    .ant-menu-item{
        svg{
            font-size: 20px !important;
        }
    }
}


@media (max-width: 768px) {
    .mobileSidebar{
        padding: 0;
        :global{
            .ant-drawer-content-wrapper, .ant-drawer-content {
                border-radius: 0 20px 20px 0 !important;
            }
            .ant-drawer-content-wrapper{
                width: 68% !important;
            }
            .ant-drawer-content{
                .ant-drawer-body{
                    padding: 0 !important;
                }
            }
        }
    }
    .sidebarContainer {
    top: 50px;
     width: var(--sidebar-width);
     display: block; 
     overflow-y: auto !important; 
     height: 100vh !important ;
           
    }
         a {
          text-decoration: none !important;
         }
    .toggleButton {
        position: fixed;
        top: 18px;
        left: 10px;
        z-index: 10000; 
        border: none !important;
        padding: 0 !important;
        font-size: 18px;
        &:hover{
            color: var(--primary-color);
        }
    }

 
    .ant-drawer {
        .ant-drawer-content {
            padding: 0;
        }
        .ant-menu {
            width: 200px;
            background-color: #001529;
            padding-top: 20px;
            
        }
     
        .ant-menu-item,
        .ant-menu-submenu-title {
            color: #fff;
            &:hover {
                background-color: #1890ff;
                color: #fff;
            }
        }

        .ant-menu-item-selected {
            background-color: #1890ff;
            color: #fff;
        }
        .ant-menu-submenu-title {
            color: #fff;
        }
    }

 
    .drawerMenu {
        width: 100%;
        .ant-menu-item,
        .ant-menu-submenu-title {
            color: #fff;
        }

        .ant-menu-item a {
            color: inherit;
        }
    }
}


@media (min-width: 768px) {
    .toggleButton {
        display: none;
    }
}
