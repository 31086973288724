.liveMainDiv {
    display: flex;
  
    .leftSection {
      flex: 2;
      margin-right: 20px;
  
      .noVideoCard {
        width: 100%;
        text-align: left;
        border: none;
      }
    }
  
    .rightSection {
      flex: 1;
      margin-top: 50px;
    }
  }
  
  @media (max-width:768px)
  {
    :global{
      .ant-card-body{
        overflow-y: auto;
      }
    }
  }
  