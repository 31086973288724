.statsCard{
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.03) !important;
    border-radius: 16px;

    :global{
        .ant-card-body{
            padding: 12px 20px;
        }
    }
    .cardBody{
        display: flex;
        flex-direction: row;
        align-items: center;

        .iconDiv{
            min-width: 46px;
            height: 46px;
            width: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            margin-right: 16px;
            font-size: 20px;
            border-radius: 50%;
        }
        .contentDiv{
            h6{
                margin-bottom: 3px;
                font-size: 18px;
            }
            p{
                margin-bottom: 0;
                font-size: 14px;
                color: rgb(107, 114, 128);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
@media (max-width: 768px) {
    .statsCard{
        margin-bottom: 15px !important;
    }
    .cardBody {
        flex-direction: column !important;
        justify-content: center;
        gap: 10px;
        .iconDiv{
            margin-right: 0 !important;
        }
       .contentDiv{
            text-align: center;
       }
    }
}
