.paymentItem {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  a {
    margin-left: 10px;
  }
}

.payHeading {
  margin-top: 20px;
  margin-bottom: 1rem;
}
