// .createDrawer{
//     padding-bottom: 75px;

//     .drawerMain{
//         padding-top: 20px;
//     }
// }
.createDrawer{
    :global{
        .ant-drawer-header-title{
            .ant-drawer-title{
                font-size: 20px ;
                font-weight: 700;
            }
        }
    }
}

