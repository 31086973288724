.couponSection {
    margin: 20px 0;
    display: flex;
   // justify-content: center; /* Center the coupon card */
}

.couponCard {
    max-width: 200px; /* Set a maximum width for the coupon card */
    width: 50%; /* Ensure it takes full width up to max-width */
    padding: 20px;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    background-color: #fff;
    // text-align: center; /* Center text inside the card */
}

.couponTitle {
    font-size: 20px;
    color: #4CAF50;
}

.couponDescription {
    font-size: 16px;
    margin: 10px 0;
}

.statSection{
    margin-top: 1rem;
    margin-bottom: 2.5rem;
}

.liveClasses{
    margin-top: 1rem;
}

.offlineClasses{
    margin-top: 2rem;
    .tableDiv{
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
        padding: 0;
        border-radius: 8px;
        overflow: hidden;
    }
}
.batchDiv {
    display: flex;
    padding: 20px;
    position: relative;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    flex-direction: column;
    background-color: #ffffff;
    overflow: hidden;
    cursor: pointer;
    justify-content: center;
    gap: 10px;
    margin-bottom: 35px;
    .batchCardImg{
        width: 100%;
        height: 200px;
        overflow: hidden;
        text-align: center;
        img{
            height: 100%;
            object-fit: cover;
        }
    }
    .batchContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
        width: 100%;
        .smallContent{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            gap: 8px;
            p{
                margin-bottom: 0;
                font-size: 14px;
                display: flex;
                align-items: center;
                gap: 5px;
                svg{
                    font-size: 17px;
                }
                &:last-child{
                    svg{
                        font-size: 15px;
                    }
                }
            }
        }
        .heading {
            font-size: 16px;
            font-weight: 700;
        }
    }

    .addStudent {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        align-items: center;

        p {
            background-color: rgb(245, 237, 252);
            display: inline-block;
            padding: 3px 8px;
            border-radius: 8px;
            font-size: 13px;
            font-weight: 600;
            color: rgb(158, 73, 230);
            margin: 0;

            svg {
                font-size: 16px;
                margin-right: 2px;
            }
        }

        button {
            border-radius: 50px;
            border: 1px dashed #d5d5d5;
            color: #898989;
            font-weight: 500;
            font-size: 14px;

            &:hover {
                background-color: var(--bg-theme-dark-color) !important;
            }
        }
    }

    .footer {
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 7px;
        position: absolute;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: var(--primary-color);
        background-color: var(--bg-theme-dark-color);
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;

        .subjectName {
            color: var(--primary-color);
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
        }

        svg {
            font-size: 16px;
        }
    }

    .popup {
        gap: 1px;
        top: 25px;
        right: 0px;
        width: max-content;
        border: 1px solid #d8e0f0;
        display: flex;
        z-index: 8;
        overflow: hidden;
        position: absolute;
        min-width: 150px;
        box-shadow: 0px 2px 4px #28293d0a, 0px 8px 16px #60617029;
        border-radius: 14px;
        flex-direction: column;
        background-color: #ffffff;

        .listItem {
            color: rgba(125, 133, 146);
            cursor: pointer;
            display: flex;
            padding: 8px 8px 8px 12px;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;

            &:hover {
                background-color: rgba(240, 240, 240, 0.5); // Optional hover effect
            }
        }
    }
}



.coursesSection {
    padding: 3rem 0 1.5rem;
    .courseCard{
        padding-bottom: 32px;
        margin-bottom: 2rem;
        
        .createdByTag{
            color: var(--primary-color);
            font-size: 13px;
            font-weight: 600;
            position: absolute;
            width: 100%;
            left: 0;
            padding: 5px 15px;
            bottom: 0;
            margin-bottom: 0;
            background-color: var(--bg-theme-dark-color);
            text-align: center;
            span{
                // background-color: var(--bg-theme-dark-color);
                display: inline-block;
                margin-left: 2px;
            }
        }
        .tagsDiv{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0 5px;
            
            .downloadCourse{
                background-color: rgb(245, 237, 252);
                display: inline-block;
                padding: 3px 5px;
                border-radius: 8px;
                font-size: 13px;
                font-weight: 600;
                color: rgb(158, 73, 230);
                margin: 0;
            }
            .priceCourse{
                display: inline-block;
                font-size: 14px;
                margin-bottom: 0;
                span{
                    font-size: 16px;
                    font-weight: 700;
                }
                div{
                    color: #bcbcbc;
                    text-decoration: line-through;
                    display: inline-block;
                    margin-left: 5px;
                }
            }
        }
    }

}

.actionBtnsSection {
    padding-bottom: 3rem;

    .filterDiv {
        display: flex;
        flex-direction: row;
        gap: 20px;

        input {
            width: 300px;
            height: 35px;
        }

        .sortSelect {
            font-size: 14px;
            height: 45px;
            width: 200px;
        }
    }

    .actionBtnDiv {
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: flex-end;

        button {
            font-weight: 600;
            font-size: 15px;
            height: 45px;
            width: 150px;
        }
    }
}
:global{
    .ant-card-body{
        min-height: auto !important;
    }
    .imageCard_contentDiv__t-k0G{
        min-height: auto !important;
    }
}
.sectionHeading{
    margin-bottom: 2rem;
    margin-top: 2rem;
}
.tableSection{
    padding: 0 0 2rem;
    .tableCard{
        padding: 10px;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);

        .tableUserImg{
            img{
                height: 30px;
                border-radius: 50%;
                margin-right: 5px;
            }
        }
    }
}
.liveClassDashboard{
    // background-color: #ffffff;
    border-radius: 8px;
    max-height: 400px;
    overflow: auto;
    // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    .noDataText{
        text-align: left;
        font-size: 15px;
        margin-bottom: 0;
    }
    :global{
        .ant-list-empty-text{
            padding: 0;
        }
        .ant-list-items{
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          padding: 5px 0;
          .ant-list-item{
            width: calc(50% - 15px);
            background: #ffffff;
            border-radius: 8px;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
          }
        }
      }
    h3{
        font-size: 16px;
    }
    .actions{
        display: flex;
        margin: 20px;
        gap: 10px;
    }
}

@media (max-width:768px){
    .offlineClasses{
    .tableDiv{
        overflow-y: auto;
        overflow: auto;
    }
}
:global{
    .ant-list-item{
        display: block !important;
        width: 100% !important;

    }
}
}