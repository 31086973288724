.menuManager {
  position: relative;
  background: #ffffff;
  overflow: hidden;

  :global{
    .ant-list-header{
      background-color: var(--bg-theme-dark-color);
      font-weight: 700;
      font-size: 16px;
    }
    .ant-list-bordered{
      overflow: hidden;
    }
  }
}

.showButton {
  margin: 16px;
}

.menuDrawer {
  .ant-drawer-body {
    padding: 0;
  }
}

.ant-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
