.announcementList{
    margin-top: 2rem;
    background-color: #ffffff;
    .description{
        max-width: 92%;
        margin-bottom: 0;
    }
    .createdDetails{
        display: flex;
        gap: 20px;
        p{
            margin: 10px 0 0;
        }
    }
}
.actionBtnsSection {
    padding-bottom: 1rem;


    .filterDiv {
        display: flex;
        flex-direction: row;
        gap: 20px;

        :global {

            .ant-input-affix-wrapper,
            .ant-select-selector {
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
            }
        }

        input {
            width: 300px;
            height: 35px;
        }

        .sortSelect {
            font-size: 14px;
            height: 45px;
            width: 60%;
        }
    }

    .actionBtnDiv {
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: flex-end;

        button {
            font-weight: 600;
            font-size: 15px;
            height: 45px;
        }
    }
}
.drawerMain{
    padding: 1rem 2rem;
}
.formFooter {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 30px;
    border-top: 1px solid #e5e5e5;
    justify-content: flex-end;
    background: #ffffff;
    border-radius: 20px 0 0 20px;

    button {
      height: 45px;
      width: 150px;
    }
  }
