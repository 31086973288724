.priceModal{
    :global{
        .ant-modal-title{
            font-size: 20px;
            font-weight: 700;
        }
    }
    .modalFormStyle{
        margin: 2rem 0 1rem;
        .formFooter{
            margin-top: 20px;
            display: flex;
            gap: 8px;
            justify-content: flex-end;
            button{
                height: 40px;
            }
        }
    }
    .validityPeriod{
        margin-bottom: 1rem;
    }
}
