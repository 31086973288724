.loaderMain{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.7);
    svg {
        font-size: 60px;
        animation: spin 1s linear infinite;
        color: var(--primary-color);
        }
        
        @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
        }
}