:root {
    --primary-color: #02b3e4;
    --bg-theme-color:#f5f7ff;
    --bg-theme-dark-color:#e6faff;
    --sidebar-width:250px;
    --header-width:71px;
  }
  a{
    color: var(--primary-color);
  }
  img{
    max-width: 100%;
    height: auto;
  }
  .ant-select-single {
    font-size: 14px;
    height: 40px;
  }
  input,
  .multiSelect,
  .ant-picker.ant-picker-outlined{
    height: 40px;
  }
  .ant-input-number{
    height: 40px;
    width: 100%;
  }
// .ant-input-affix-wrapper,
// .ant-select-selector,
// .ant-form-item-control-input-content:has(input){
//   box-shadow: 0 0 3px 0 rgba(0,0,0,0.08);
// }
.ant-form-item-control-input-content:has(.ant-upload),
.ant-form-item-control-input-content:has(.ant-radio-group),
.ant-form-item-control-input-content:has(.ant-checkbox-wrapper)
{
  box-shadow: none;
}
.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  position: absolute;
  right: 0;
}
.ant-drawer {
  .ant-drawer-body{
    padding: 24px 24px 80px !important;
}
}


.ant-drawer-content-wrapper,
.ant-drawer-content{
 
  border-radius: 20px 0  0 20px !important;
} 

.ant-input,
.ant-input-number,
.ant-select-selector,
.ant-btn-dashed,
.ant-input-affix-wrapper,
.ant-picker-outlined,
.ant-input-number-affix-wrapper{
  border-radius: 25px !important;
  padding-left:12px !important;
}

.ant-btn,.ant-btn-icon-only{
  
  padding-inline: 11px !important;
}
  
.ant-btn{
  border-radius: 25px !important;
}

.ant-rate{
  padding: 5px;
  font-size: 44px;
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Make the scrollbar thinner */
  scrollbar-color: #888 #f4f4f4; /* Thumb color and track color */
}
/* For Webkit-based browsers (Chrome, Edge, Safari) */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height for horizontal scrollbar */
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  background: #f4f4f4; /* Background of the track */
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 50px; /* Rounded corners */
  border: 2px solid #f4f4f4; /* Space between thumb and track */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Hover effect on scrollbar thumb */
}

@media (max-width:768px){
  img{
    max-width: 100%;
  }
}