.actionBtnsSection {
  padding-bottom: 3rem;

  .actionBtnDiv {
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: flex-end;

        button {
          font-weight: 600;
          font-size: 15px;
          height: 45px;
          svg{
            font-size: 18px;
          }
      }
  }
}

.coupon {
  border: 1px dashed var(--primary-color);;
  background-color: hsl(0, 0%, 100%);
  padding: 5px;
  width: 100%;
  text-align: center;
  font-family: 'Arial', sans-serif;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;

 

  .couponActionBtns{
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    padding: 5px;
    display: none;

    span{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      font-size: 18px;
      border-radius: 4px;
      cursor: pointer;
      background-color: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      transition: 0.3s ease-in-out;
      &:hover{
        background-color: var(--primary-color);
        color: #ffffff;
        border-color: var(--primary-color);
      }
    }
  }
  &:hover{
    .couponActionBtns{
      display: flex;
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: var(--bg-theme-color);
    border: 1px dashed var(--primary-color);
    border-radius: 50%;
  }

  &::before {
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    border-bottom: 0;
    border-top: 0;
    border-left: 0;
  }

  &::after {
    right: -25px;
    top: 50%;
    transform: translateY(-50%);
    border-bottom: 0;
    border-top: 0;
    border-right: 0;
  }

  .coupon-header {
    background-color: var(--primary-color);
    color: white;
    padding: 15px 0;
    border-radius: 8px 8px 0 0;

    &.activeCoupon {
      background-color: var(--primary-color);
     
  }
  
  &.inactiveCoupon {
      background-color: gray;
      color: white;
  }

    h2 {
      margin: 0;
      font-size: 24px;
      font-weight: bold;
    }
  }

  .coupon-body {
    padding: 15px;
    p{
      font-size: 13px;
    }
    h6{
      text-transform: uppercase;
      font-weight: 600;
      display: inline-block;
      border: 1px dashed #cacaca;
      // border: 1px dashed var(--primary-color);
      padding: 10px;
      margin: 15px 0 10px;

      strong {
        color: var(--primary-color);
        font-weight: 700;
      }
    }
  }

  .coupon-footer {
    padding: 10px;
    font-size: 14px;
    color: #555;
  }

  .coupon-btn {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;

    &:hover {
      background-color: var(--primary-color);
    }
  }
}

@media (max-width: 768px) {
  .actionBtnDiv{
    width: 100% !important;
  button {
   width: 100% !important;
  }
}
.coupon{
  margin-bottom: 3rem;
}
}
