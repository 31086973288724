.coursesSection {
    padding: 3rem 0;
    .courseCard{
        padding-bottom: 32px;
        margin-bottom: 2rem;
        position: relative;
        overflow: hidden;
        padding: 16px;
        @media (max-width:768px){
            padding: 16px 16px 30px 16px;
        }
        .courseListContent{
            padding: 15px 0;
        }
        .contentDiv{
            padding: 0;
        }
        .goldenStar {
            color: gold;
            position: absolute;
            top: 17px;  
            left: 17px; 
            font-size: 24px;
            z-index: 20;
            background-color: rgba(255, 255, 255, 0.8); 
            border-radius: 50%;
            padding: 4px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        }

        .badge {
            position: absolute;
            top: 17px;
            right:18px;
            padding: 4px 12px;
            border-radius: 12px;
            font-size: 11px;
            color: #fff;
            font-weight: 500;
            z-index:23;
        }
        
        .published {
            color: #389e0d;
            background: #f6ffed;
            border-color: #b7eb8f; // Green for published
        }
        
        .unpublished {
            color: #d4380d;
            background: #fff2e8;
            border-color: #ffbb96; // Red for unpublished
        }
        .coursesActionBar{
            position: absolute;
            right: 0;
            z-index: 9;
            top: 0;
            transform: translateX(100%);
            transition: 0.3s ease-in-out;
            width: 52%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: end;
            div{
                background-color: #ffffff;
                margin: 6px 0;
                padding: 2px 6px;
                border-radius: 5px 0 0 5px;
                width: 85%;
                cursor: pointer;
                box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
                font-size: 13px;
                transition: 0.2s ease-in-out;
                font-weight: 500;
                svg{
                    font-size: 16px;
                    margin-right: 2px;
                }
                &:hover{
                    background-color: var(--primary-color);
                    color: #ffffff;
                }
            }
        }
        &:hover{
            .coursesActionBar{
                background-color: rgba(0,0,0,0.7);
                transform: translateX(0);
            }
            // .badge {
            //     display: none; 
            // }
        
        }
        :global{
            .ant-card-body{
                min-height: 330px !important;
                @media (max-width:768px) {
                    min-height: auto !important;
                }
            }
        }
        .createdByTag{
            color: var(--primary-color);
            font-size: 13px;
            font-weight: 600;
            position: absolute;
            width: 100%;
            left: 0;
            padding: 5px 15px;
            bottom: 0;
            margin-bottom: 0;
            background-color: var(--bg-theme-dark-color);
            text-align: center;
            span{
                // background-color: var(--bg-theme-dark-color);
                display: inline-block;
                margin-left: 2px;
            }
        }
        .tagsDiv{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0 5px;
            p{
                margin-bottom: 0;
            }
            .durationCourse{
                background-color: rgb(245, 237, 252);
                display: inline-block;
                padding: 3px 5px;
                border-radius: 8px;
                font-size: 13px;
                font-weight: 600;
                color: rgb(158, 73, 230);
                margin: 0;
            }
            .priceCourse{
                display: inline-block;
                font-size: 14px;
                margin-left: 10px;
                margin-bottom: 0;
                i{
                    font-size: 13px;
                    color: #bcbcbc;
                }
                span{
                    font-size: 16px;
                    font-weight: 700;
                }
                div{
                    color: #bcbcbc;
                    text-decoration: line-through;
                    display: inline-block;
                    margin-left: 5px;
                }
            }
        }
    }

}

.actionBtnsSection {
    padding-top: 3rem;
    @media (max-width:768px) {
        padding-top: 1rem;
    }
    .filterDiv {
        display: flex;
        flex-direction: row;
        gap: 20px;

        input {
            width: 300px;
            height: 35px;
        }

        .sortSelect {
            font-size: 14px;
            height: 45px;
            width: 45%;
        }
    }

    .actionBtnDiv {
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: flex-end;

        button {
            font-weight: 600;
            font-size: 15px;
            height: 45px;
            min-width: 150px;
            @media (max-width:768px) {
                height: 36px;
            }
        }
        
    }
}
.durationCourse{
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .filterDiv {
        width: 100%;
    }

    .actionBtnDiv {
        gap: 15px;
        width: 100%;
        margin-top: 15px;

        button {
            width: 100% !important; // Full-width buttons
        }
    }
}

