.courseContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .courseLeftSection,
  .courseRightSection {
    flex: 1;
    width: 35%;
  }

  .contentStats {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border: 1px dashed var(--primary-color);
    padding: 10px;

    span {
      font-size: 14px;
      font-weight: 500;

      strong {
        margin-right: 5px;
      }

      svg {
        font-size: 18px;
        color: var(--primary-color);
        margin-right: 3px;
      }
    }
  }

  .courseLeftSection {
    .courseHeader {
      display: flex;
      align-items: center;
      gap: 15px;

      .courseImage {
        border-radius: 8px;
      }

      .courseInfo {
        h2 {
          font-size: 24px;
        }

        p {
          font-size: 14px;
          color: #888;
        }
      }

      .courseActions {
        display: flex;
        gap: 10px;
        margin-top: 10px;

        button {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }

    .contentSummaryCard {
      margin-top: 20px;

      h4 {
        font-size: 22px;
      }
    }

    .contentSummary {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      span {

        padding: 5px 10px;
        border-radius: 8px;
      }
    }

    h4 {
      margin-top: 20px;
      font-size: 16px;
    }

    .ant-list-item {
      padding: 8px 16px;
    }
  }

  .courseRightSection {
    .paymentDetails {

      .paymentItem {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;

        a {
          margin-left: 10px;
        }
      }

      h3 {
        font-size: 18px;
        margin: 10px 0;
      }

      .divider {
        margin: 15px 0;
      }

      .ant-btn {
        margin-top: 16px;
      }

      p {
        font-size: 12px;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}

.courseDescription {
  margin-top: 20px;
  white-space: pre-line;
}

@media(max-width:768px) {

  .courseContainer {
    flex-direction: column;

    .courseLeftSection,
    .courseRightSection {
      width: 100%;
    }

    .paymentDetails {
      .paymentItem {
        flex-direction: column;
        gap: 10px;

      }
    }
  }
}