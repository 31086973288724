// overview.module.scss

.courseInfo {
  display: flex;
  align-items: flex-start; // Align items vertically in the center
  flex-direction: column;
  gap: 10px;
  text-align: flex-end;
}

.courseImage {
  display: block;
  // margin: 0 auto;
  width: 50%; // Ensure image width is consistent
  border-radius: 8px;
}

.courseDetails {
  flex: 1;
  margin-left: 1rem; // Spacing between image and text
}

.cardHeading {
  font-size: 1.125rem; // Font size for the course title
  font-weight: 600; // Font weight for emphasis
  margin-bottom: 1rem; // Spacing below the title
}

.courseDate {
  margin: 0.625rem 0; // Spacing above and below the course date
}

.description {
  margin-top: 0.10rem; // Spacing above the description
  white-space: break-spaces;
}
.container {
    display: flex;
    justify-content: space-between;
    // background-color: transparent;
  }

  .leftSection {
    flex: 1;
    margin-right: 16px;
  }

  .rightSection {
    width: 30%;
    :global{
       .ant-card-head{
        background-color: #f1f1f1;
        color: black;
       }
      .ant-card-body{
        padding: 2px;
      }
    }
  }

  .batchInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    margin-top: 16px;
  }

  .batchInfoItem {
    display: flex;
    align-items: center;
    flex-basis: calc(50% - 16px); /* Two items per row */
    margin-bottom: 8px;
    svg{
      margin-right: 8px;
      font-size: 18px;
    }
  }

  .batchInfoItem span {
    margin-right: 8px;
  }

  .batchInfoItem strong {
    font-weight: bold;
  }

  .icon {
    margin-right: 8px;
    font-size: 18px;
  }

  .batchTimingsCard {
    margin-top: 16px;
  }

  .batchTimingsContent {
    text-align: center;
    .emptyDataDiv{
      color: #989898;
      svg{
        font-size: 25px;
        margin-bottom: 10px;
      }
      h4{
        font-size: 16px;
        font-weight: 400;
        font-style: italic;
      }
    }
  }


  :global {
    .ant-layout {
      background: none !important; // Or your custom color
    }
  }


