.coursesSection {
    padding: 3rem 0 1.5rem;

    .courseCard {
        padding-bottom: 32px;
        margin-bottom: 2rem;

        .createdByTag {
            color: var(--primary-color);
            font-size: 13px;
            font-weight: 600;
            position: absolute;
            width: 100%;
            left: 0;
            padding: 5px 15px;
            bottom: 0;
            margin-bottom: 0;
            background-color: var(--bg-theme-dark-color);
            text-align: center;

            span {
                display: inline-block;
                margin-left: 2px;
            }
        }

        .tagsDiv {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0 5px;

            .downloadCourse {
                background-color: rgb(245, 237, 252);
                display: inline-block;
                padding: 3px 5px;
                border-radius: 8px;
                font-size: 13px;
                font-weight: 600;
                color: rgb(158, 73, 230);
                margin: 0;
            }

            .priceCourse {
                display: inline-block;
                font-size: 14px;
                margin-left: 0;
                margin-bottom: 0;

                span {
                    font-size: 16px;
                    font-weight: 700;
                }

                div {
                    color: #bcbcbc;
                    text-decoration: line-through;
                    display: inline-block;
                    margin-left: 5px;
                }
            }
        }
    }
}

.sectionHeading {
    margin-bottom: 2rem;
}

:global {
    .ant-card-body {
        min-height: auto !important;
    }

    .imageCard_contentDiv__t-k0G {
        min-height: auto !important;
    }
}
