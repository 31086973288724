

.formContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
    .formLeft{
      width: 47%;
    }
    .formRight{
      width: 47%;
    }
  }

  .submitButton {
    width: 100%;
    margin-top: 2rem;
  }

.settingTabs{
    padding: 20px; 
    background-color: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
    border-radius: 8px;
    
    :global{
        .ant-tabs-tab{
          .ant-tabs-tab-btn{
            display: flex;
            gap: 5px;
            align-items: center;
          }
          svg{
              font-size: 18px;
          }
        }
        .ant-btn-primary{
            height: 40px;
        }
        .ant-tabs-nav-list{
            font-weight: 600;
        }
    }
}
.companyInfoImage{
  display: flex;
}
.mobileTabs {
  margin-bottom: 20px;

  .ant-btn {
      width: 100%;
  }
}

@media(max-width:768px)
{
  .settingTabs{
    overflow-x: auto;
    // overflow-y: auto;
    // height: 300px;
}
 .formContainer{
  display: flex;
  max-width: 100%;
 
 }
 :global{
  .ant-tabs-tabpane{
    margin: 0;
    padding-left: 0;
    width: fit-content;
  }
  .ant-form-item .ant-form-item-label >label{
    font-size: 12px;
    
  }
 }
}