
    :global{
        .ant-drawer-body .ant-form-vertical{
            margin-top: 0 !important;
           
        }
        .ant-steps{
            position: absolute;
            top: 57px;
            z-index: 99;
            background: #fff;
            width: 100%;
            left: 0;
            padding: 10px 25px;
        }
        // .ant-drawer{ 
        //     .ant-drawer-body{
        //         padding-top: 40px;
        //         padding-bottom: 60px;
        //     }
        // }
    }
    .basicInfoForm {
        margin-bottom: 20px;
        .note {
            display: flex;
            gap: 5px;
            margin: 5px 0;
            color: #898989;
            font-size: 12px;
        }

        .categoryList {
            display: flex;
            flex-direction: column;
            :global{
                .ant-space-item{
                    width: 50%;
                    .ant-select-selector{
                        min-height: 40px !important;
                    }
                }
            }
            .categoryRow {
                display: flex;
                gap: 10px;
            }
        }

        :global {
            .ant-space-item {
                width: 45%;

                &:last-child {
                    width: 10%;
                }
            }
        }
    }

    .priceSection {
        :global {
            .ant-space-item {
                width: 33% !important;
            }
        }
    }

.formFooter {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 30px;
    border-top: 1px solid #e5e5e5;
    justify-content: flex-end;
    background: #ffffff;
    z-index: 100;
    border-radius: 20px 0 0 20px;
    button {
        height: 45px;
        width: 150px;
    }
}
.uploadImage{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px dashed #e5e5e5;
    border-radius: 5px;
    padding: 8px;
    gap: 20px;

    ul{
        padding-left: 0;
        margin: 0;

        li{
            color: #898989;
            margin-bottom: 6px;
        }
    }
}
.addContentBar{
    width: 50px;
    border-radius: 8px;
    ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
        li{
            font-size: 13px;
            margin-bottom: 15px;
            position: relative;
            height: 40px;
            max-width: 40px;
            border-radius: 50px;
            background-color: var(--primary-color);
            box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
            border: 1px solid var(--primary-color);
            color: #ffffff;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            &:hover{
                max-width: 150px;
                p{
                    opacity: 1;
                    display: inline-block;
                }
            }
            .contentIcon{
                display: inline-block;
                cursor: pointer;
                transition: 0.2s ease-in-out;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                svg{
                    font-size: 18px;
                }
            }
            p{
                display: none;
                opacity: 0;
                margin-bottom: 0;
                margin-left: 5px;
                transition: 0.3s ease-in-out;
                // position: absolute;
                // left: 38px;
                // top: 3px;
                padding: 5px 20px 5px 10px;
                border-radius: 25px;
                box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
                border: 1px solid var(--primary-color);
                z-index: 9;
                transition: 0.3s;
                white-space: nowrap;
                font-weight: 600;
                background-color: #ffffff;
                color: var(--primary-color);
            }
        }
    }
}