// Define common variables
$box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
$font-color-dark: #000000;
$font-color-light: #a5a5a5;
$bg-white: #ffffff;
$primary-color: var(--primary-color);
$secondary-color: var(--bg-theme-dark-color);

// Common button styles
@mixin button-style {
  font-weight: 600;
  font-size: 15px;
  height: 45px;
}

// Segment control
.segmentDiv {
  margin: 2rem auto;
  width: 60%;
  box-shadow: $box-shadow;

  :global {
    .ant-segmented-item {
      width: 50%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      font-weight: 600;
      font-size: 15px;
      text-transform: capitalize;

      &.ant-segmented-item-selected {
        color: $primary-color;
      }

      .ant-segmented-item-icon {
        svg {
          font-size: 18px;
        }
      }
    }
  }
}

// Card structure shared for both videos and documents
.resourceCard {
  background-color: $bg-white;
  box-shadow: $box-shadow;
  border-radius: 3px;
  overflow: hidden;
  padding: 5px;
  margin-bottom: 1.5rem;

  h5 {
    color: $font-color-dark;
    margin: 12px 5px 7px;
    font-size: 16px;
  }

  .thumbnailDiv {
    position: relative;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .playIcon {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      flex-direction: column;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      top: 5px;
      left: 5px;
      cursor: pointer;

      svg {
        font-size: 60px;
        color: $bg-white;
      }
    }
  
  }
}

// Action buttons for resources
.actionBtnsSection {
  padding-bottom: 1rem;

  .filterDiv {
    display: flex;
    flex-direction: row;
    gap: 20px;

    :global {
      .ant-input-affix-wrapper,
      .ant-select-selector {
        box-shadow: $box-shadow;
      }
    }

    input {
      width: 300px;
      height: 35px;
    }

    .sortSelect {
      font-size: 14px;
      height: 45px;
      width: 45%;
    }
  }

  .actionBtnDiv {
    display: flex;
    gap: 20px;
    justify-content: flex-end;

    button {
      @include button-style;
    }
  }
}

// No data message
.noDataDiv {
  p {
    color: $font-color-light;
    margin-top: 2rem;
  }
}

  .videoCard{
      background-color: #ffffff;
      box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
      border-radius: 3px;
      overflow: hidden;
      padding: 5px;
      margin-bottom: 1.5rem;


      h5{
          color: #000000;
          margin: 12px 5px 7px;
          font-size: 16px;
      }
      .videoThumbnailDiv{
          position: relative;

          >img{
              height: 100%;
              width: 100%;
              object-fit: cover;
          }
          .playIcon{
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              flex-direction: column;
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              margin: auto;
              top: 5px;
              left: 5px;
              cursor: pointer;
              // background-color: rgba(0,0,0,0.5);
              border-radius: 3px;
              overflow: hidden;

              svg{
                 font-size: 60px;
                 color: #ffffff;
              }
          }
      }
      .videoPlayer {
        width: 100%;
        max-width: 600px;
        height: 185px;
      
      }
  }
  .actions{
    button{
      width: 100%;
      border-color: var(--primary-color) !important;
      color: var(--primary-color);
    }
  }
  .documentCard {
    background-color: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    padding: 10px 10px 12px;
    margin-bottom: 1.5rem;

    h5 {
        color: #000000;
        margin: 15px 3px;
        font-size: 16px;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .documentThumbnailDiv {
        position: relative;
        height: 185px;
        overflow: hidden;

        > img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .playIcon {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            flex-direction: column;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            margin: auto;
            top: 5px;
            left: 5px;
            cursor: pointer;

            svg {
                font-size: 60px;
                color: #ffffff;
            }
        }
    }
}