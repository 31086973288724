.actionBtnDiv {
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: flex-end;

    button {
        font-weight: 600;
        font-size: 15px;
        height: 45px;
    }
}
.mobileTabs {
    margin-bottom: 20px;

    .ant-btn {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .mobileTabs {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;

        .ant-btn {
            width: 100%;
        }
    }
}